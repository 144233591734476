<template>
  <FimButton
    type="tertiary"
    class="block size-9 text-center"
    no-padding
    :disabled="disabled"
    @click="goToPage"
  >
    <slot />
  </FimButton>
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from '#vue-router'

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  page: {
    type: Number,
    required: true,
  },
})
const route = useRoute()
const router = useRouter()

const to = computed(() => {
  const attributes: Partial<RouteLocationRaw> = {
    path: route.path,
    query: {
      ...route.query,
      page: props.page.toString(),
    },
  }

  if (props.page === 1) {
    delete attributes?.query?.page
  }

  return attributes
})
const goToPage = () => {
  router.replace(to.value)
  window.scroll({ behavior: 'smooth', top: 0 })
}
</script>
