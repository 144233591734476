<template>
  <div v-click-outside="close" class="relative">
    <slot>
      <div>
        <slot name="label" v-bind="{ toggle }" />
      </div>
      <div v-if="isOpen" class="absolute z-30 w-full border border-slate-200">
        <slot name="menu-content" v-bind="{ toggle, close }" />
      </div>
    </slot>
  </div>
</template>

<script setup lang="ts">
// @ts-expect-error no typings
import vClickOutside from 'vue-click-outside'

const isOpen = useState('isOpen', () => false)

const close = () => (isOpen.value = false)
const toggle = () => (isOpen.value = !isOpen.value)
</script>
