<template>
  <Intersect
    :threshold="0.5"
    v-bind="{ ...(blok.id ? { id: blok.id } : {}) }"
    class="col-span-full border-t border-gray-400"
    :class="{
      'sm:col-span-6': blok.width === '1',
      'xl:col-span-4': blok.width === '1',
      '2xl:picture-cover 2xl:col-span-3': blok.width === '1',
      'sm:col-span-12': ['full', '2'].includes(blok.width as any),
      'xl:col-span-8': blok.width === '2',
      '2xl:col-span-6': blok.width === '2',
      'xl:col-span-12': blok.width === 'full',
      '2xl:col-span-12': blok.width === 'full',
    }"
    @enter="intersectPromotionView"
  >
    <KeepAlive v-for="entry in blok.content" :key="entry._uid">
      <StoryblokComponent
        :blok="entry"
        class="h-full"
        link-classes="h-full"
        :width="blok.width"
        :ratio-mobile="blok.width === '1' ? 280 / 300 : 560 / 300"
        :ratio-desktop="blok.width === '1' ? 280 / 300 : 560 / 300"
        @click:toggle-product-wishlist="$emit('click:toggle-product-wishlist')"
        @click:product="$emit('click:product')"
      />
    </KeepAlive>
  </Intersect>
</template>

<script setup lang="ts">
import type { SbListingDisruptor } from '~/storyblok/types/storyblok.gen'

type Props = {
  blok: SbListingDisruptor
  index?: number
}

defineEmits(['click:toggle-product-wishlist', 'click:product'])

const props = withDefaults(defineProps<Props>(), {
  index: undefined,
})

const { trackPromotionView } = await useTrackingEvents()

const intersectPromotionView = (
  _: IntersectionObserverEntry,
  stop: () => void,
) => {
  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionView(props.blok)
  }
  stop()
}
</script>
<style scoped>
@media (width >= 90rem) {
  /* stylelint-disable-next-line selector-class-pattern */
  .\32xl\:picture-cover :deep(img) {
    object-fit: cover;
  }
}
</style>
