<template>
  <div>
    <slot v-if="loading" name="loading-content">
      <ProductCardSkeleton
        v-for="index in Math.min(productCountLoading, perPage)"
        :key="`product-loading-${index}`"
        type="custom"
        class="col-span-12 sm:col-span-6 xl:col-span-4 2xl:col-span-3"
      />
    </slot>

    <template v-else>
      <slot
        v-for="(product, index) in products || []"
        name="product"
        :product="product"
        :loading="loading"
        :refreshing="refreshing"
      >
        <template
          v-if="hasDisruptorAtIndex(index) && hasFirstIndexOfRow(index)"
        >
          <NuxtLazyHydrate when-visible>
            <ListingDisruptor
              v-for="disruptor in getDisruptorsForRowByIndex(index)"
              :key="disruptor._uid"
              :index="index"
              :blok="disruptor"
              data-testid="list_teaser"
              @click:product="$emit('click:product', product, index)"
            />
          </NuxtLazyHydrate>
        </template>
        <NuxtLazyHydrate when-visible>
          <ProductCard
            :key="`product-${product.id}`"
            color-chip-size="sm"
            color-chip-rounded-size="sm"
            sibling-spacing="narrow"
            :index="index"
            :product="product"
            :placement="placement"
            class="col-span-12 border-t border-gray-400 sm:col-span-6 xl:col-span-4 2xl:col-span-3"
            @click:select-item="$emit('click:product', product, index)"
            @intersect:product="collectRowIntersection(index)"
          >
            <template #header-badge>
              <ProductListHeaderBadges :product="product" />
            </template>
          </ProductCard>
        </NuxtLazyHydrate>
      </slot>
    </template>
  </div>
</template>

<script setup lang="ts">
import { getDisruptorsForRow } from '@scayle/storefront-nuxt'
import {
  type Product,
  getRowByIndex,
  isFirstIndexOfRow,
  hasDisruptorAtRow,
} from '@scayle/storefront-nuxt'
import type { SbListingDisruptor } from '~/storyblok/types/storyblok.gen'

type Props = {
  products?: Product[]
  loading?: boolean
  refreshing?: boolean
  disruptors?: SbListingDisruptor[]
  perPage?: number
  productCountLoading?: number
}

const props = withDefaults(defineProps<Props>(), {
  products: () => [],
  loading: true,
  refreshing: true,
  disruptors: () => [],
  perPage: PRODUCTS_PER_PAGE,
  productCountLoading: PRODUCTS_PER_PAGE,
})

const { isGreaterOrEquals } = useViewport()

const emit = defineEmits<{
  (e: 'intersect:row', value: { row: number; items: Product[] }): void
  (e: 'click:product', value: Product, i: number): void
}>()
const route = useRoute()

const currentPage = computed(() => parseInt(route.query.page as string) || 1)

const columns = computed(() => {
  if (isGreaterOrEquals('lg')) {
    return 4
  }
  return isGreaterOrEquals('md') ? 3 : 2
})

const placement = computed(() =>
  route.path.includes('search') ? 'SERP' : 'PLP',
)

const _getRowByIndex = (index: number) =>
  getRowByIndex(index, {
    columns: columns.value,
    page: currentPage.value,
    perPage: 24,
  })

const trackingCollector = ref<{ row: number; items: Product[] }[]>([])

const collectRowIntersection = (index: number) => {
  const row = _getRowByIndex(index)
  const isFirstItemInRow = isFirstIndexOfRow(index, columns.value)

  if (
    isFirstItemInRow &&
    trackingCollector.value.findIndex((item) => item.row === row) === -1
  ) {
    const itemsInRow =
      props.products
        ?.slice(index, index + columns.value)
        ?.map((item, idx) => ({ ...item, index: index + idx })) ?? []
    emit('intersect:row', { row, items: itemsInRow })
    trackingCollector.value.push({ row, items: itemsInRow })
  }
}

const hasDisruptorAtIndex = (index: number) =>
  hasDisruptorAtRow(
    _getRowByIndex(index) % Math.ceil(props.perPage / columns.value),
    props.disruptors.map((d) => d.insert_in_row ?? ''),
  )
const hasFirstIndexOfRow = (index: number) =>
  isFirstIndexOfRow(index, columns.value)
const getDisruptorsForRowByIndex = (index: number) =>
  getDisruptorsForRow(
    _getRowByIndex(index) % Math.ceil(props.perPage / columns.value),
    props.disruptors as {
      insert_in_row: string
    }[],
  ) as SbListingDisruptor[]
</script>
