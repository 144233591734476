import type {
  CacheOptions,
  FilterParams,
  ProductWith,
  SortValue,
} from '@scayle/storefront-nuxt'
import useProductDefaultFimListingFilters from './useProductDefaultFimListingFilters'

export const INCLUDED_QUICK_FILTERS = []
const CACHE_TTL = 1000

const PLP_TYPE_WITH_PARAMS: Record<PlpPageType, ProductWith> = {
  'content-pages': PRODUCT_WITH_DEFAULT,
  'default-plp': PRODUCT_WITH_DEFAULT,
  'lenses-care-plp': PRODUCT_WITH_CARE,
  'lenses-plp': PRODUCT_WITH_CL,
}

export default (
  sortValue: Ref<SortValue>,
  plpType?: PlpPageType,
  categoryPathOverride?: string,
  rxCheapestLensQualityIndexPrice = 0,
) => {
  const route = useRoute()
  const { productConditions } = useQueryFilterState()
  const categoryPath = computed(() => {
    if (categoryPathOverride) {
      return categoryPathOverride
    }
    const path = route.params.category ?? ''
    return Array.isArray(path)
      ? `/${path.filter(Boolean).join('/')}`
      : `/${path}`
  })
  const withParams = computed(() =>
    plpType ? PLP_TYPE_WITH_PARAMS[plpType] : SAFE_PRODUCT_WITH,
  )

  const defaultFimFiltersPromise = useProductDefaultFimListingFilters()

  const facetPromise = useFacet({
    key: `useFacet-${categoryPath.value}`,
    params: {
      with: {
        product: withParams.value,
      },
      includeSellableForFree: true,
    },
  })
  const {
    products,
    productStatus,
    productsFetching,
    categoriesStatus,
    categories,
    pagination,
    filters,
    filtersFetching,
    filterStatus,
    fetchProducts,
    productCountData,
    refreshProductCount: _refreshProductCount,
    productCountFetching,
    unfilteredCount,
    productError,
    filterError,
  } = facetPromise

  const term = computed(() => route.query.term || '')
  const page = computed(() => route.query.page || '1')

  type FetchParameters = FilterParams & {
    path: string
    pricePromotionKey?: string
    cache?: CacheOptions
  }

  const fetchParameters = computed<FetchParameters>(() => {
    const whereConditions = rxCheapestLensQualityIndexPrice
      ? handleRxPriceOffsetFilters(
          toValue(rxCheapestLensQualityIndexPrice),
          productConditions.value.where,
        )
      : productConditions.value.where
    return {
      path: categoryPath.value,
      ...productConditions.value,
      where: {
        ...whereConditions,
        whitelistAttributes: defaultFimFiltersPromise.data.value || undefined,
        term: term.value.toString(),
        page: page.value.toString(),
      },
      perPage: PRODUCTS_PER_PAGE,
      cache: {
        ttl: CACHE_TTL,
        cacheKeyPrefix: `PLP:${categoryPath.value}`,
      },
      sort: sortValue.value,
    }
  })

  const isFiltered = computed(() => {
    return Boolean(productConditions.value.where?.attributes?.length)
  })

  const refreshProductCount = ({ where: passedWhere }: FilterParams) => {
    const whereConditions = rxCheapestLensQualityIndexPrice
      ? handleRxPriceOffsetFilters(
          toValue(rxCheapestLensQualityIndexPrice),
          passedWhere,
        )
      : passedWhere
    return _refreshProductCount({
      where: {
        ...whereConditions,
        whitelistAttributes: defaultFimFiltersPromise.data.value || undefined,
      },
    })
  }

  return extendPromise(
    Promise.all([defaultFimFiltersPromise, facetPromise]).then(() => ({})),
    {
      products,
      productStatus,
      productsFetching,
      categoriesStatus,
      categories,
      pagination,
      filters,
      filtersFetching,
      filterStatus,
      fetchProducts,
      unfilteredCount,
      fetchParameters,
      productError,
      filterError,
      categoryPath,
      productCountData,
      refreshProductCount,
      productCountFetching,
      isFiltered,
    },
  )
}
