<template>
  <ul class="space-y-0" data-testid="dropdown-list">
    <li
      v-for="item in listItems"
      :key="item.name"
      data-testid="dropdown-list-item"
    >
      <FimLink
        :only-exact-active="true"
        :class="{
          'font-medium text-black': item.name === selected.name,
        }"
        class="my-1 h-[40px] w-full whitespace-nowrap px-4 leading-[40px]"
        text-size="md"
        :bold-on-active="false"
        :to="
          fimLocalePath({
            path: route.path,
            params: { ...route.params },
            query: {
              ...route.query,
              sort: item.query,
              sortingKey: item.query,
            },
          })
        "
        @click="$emit('click:item', item)"
        >{{ $t(`sorting_select.${item.name}`) }}
        <LazyIconFielmannArrowDown
          v-if="item.name === 'price_desc'"
          class="inline size-3 align-baseline"
        />
        <LazyIconFielmannArrowUp
          v-if="item.name === 'price_asc'"
          class="inline size-3 align-baseline"
        />
      </FimLink>
    </li>
  </ul>
</template>

<script setup lang="ts">
import type { SortValue } from '@scayle/storefront-nuxt'

const fimLocalePath = useFimLocalePath()
const route = useRoute()
const baseName = useRouteBaseName()

type Props = {
  values: SortValue[]
  selected: SortValue
}

const props = defineProps<Props>()

const listItems = computed(() => {
  return props.values.map((item) => ({
    ...item,
    target: {
      name: baseName(route),
      params: { ...route.params },
      query: { ...route.query, sort: item.query },
    },
  }))
})

defineEmits<{
  (e: 'click:item', value: SortValue): void
}>()
</script>
