<template>
  <div
    v-if="lastPage !== 1"
    class="my-12 flex flex-row items-center justify-center space-x-2"
  >
    <slot name="first-button" v-bind="{ canNavigateLeft, firstPage }">
      <PaginationButton
        :disabled="!canNavigateLeft"
        :page="firstPage"
        :is-active="currentPage != firstPage"
      >
        <div class="sr-only">{{ $t('pagination.firstPage') }}</div>
        <LazyIconFielmannArrowDoubleLeft class="size-5" />
      </PaginationButton>
    </slot>

    <slot name="previous-button" v-bind="{ canNavigateLeft, previousPage }">
      <PaginationButton
        :disabled="!canNavigateLeft"
        :page="previousPage"
        :is-active="currentPage != firstPage"
        data-testid="pagination-prev-button"
      >
        <div class="sr-only">{{ $t('pagination.previous') }}</div>
        <LazyIconFielmannChevronLeft class="size-5" />
      </PaginationButton>
    </slot>

    <slot
      v-bind="{ limitedPageNumbers, currentPage }"
      :page="currentPage"
      class="border-0"
    >
      <div class="p-3 text-lg">
        {{ $t('plp.page') }} {{ currentPage }} {{ $t('plp.of') }} {{ lastPage }}
      </div>
    </slot>

    <slot name="next-button" v-bind="{ canNavigateRight, nextPage }">
      <PaginationButton
        :disabled="!canNavigateRight"
        :page="nextPage"
        :is-active="currentPage != lastPage"
        data-testid="pagination-next-button"
      >
        <div class="sr-only">{{ $t('pagination.next') }}</div>
        <LazyIconFielmannChevronRight class="size-5" />
      </PaginationButton>
    </slot>

    <slot name="next-button" v-bind="{ canNavigateRight, lastPage }">
      <PaginationButton
        :disabled="!canNavigateRight"
        :page="lastPage"
        :is-active="currentPage != lastPage"
      >
        <div class="sr-only">{{ $t('pagination.lastPage') }}</div>
        <LazyIconFielmannArrowDoubleRight class="size-5" />
      </PaginationButton>
    </slot>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  firstPage: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  lastPage: {
    type: Number,
    required: true,
  },
  visible: {
    type: Number,
    default: 5,
  },
})

const { visible, firstPage, currentPage, lastPage } = toRefs(props)

const {
  canNavigateLeft,
  canNavigateRight,
  nextPage,
  previousPage,
  limitedPageNumbers,
} = usePagination({
  firstPage,
  currentPage,
  lastPage,
  visiblePages: visible,
})
</script>
