<template>
  <FimMenu class="w-full text-center">
    <template #label="{ toggle }">
      <div class="btn-container" :data-max="maxValue">
        <div class="flex justify-end">
          <FimButton
            v-if="selected"
            :key="selected.name"
            is-small
            type="tertiary"
            class="w-full justify-center"
            data-testid="list_button_sort"
            justify="start"
            @click="toggle"
          >
            <template #icon>
              <LazyIconFielmannUpdown class="size-5" />
            </template>
            {{ $t(`sorting_select.${selected.name}`) }}
            <template #iconAfter>
              <LazyIconFielmannArrowDown
                v-if="isDesc"
                class="size-3 align-baseline"
              />
              <LazyIconFielmannArrowUp
                v-if="isAsc"
                class="size-3 align-baseline"
              />
            </template>
          </FimButton>
        </div>
      </div>
    </template>
    <template #menu-content="{ close }">
      <FadeInTransition>
        <div class="min-w-48 bg-white shadow">
          <SortingItemsList
            :values="values"
            :selected="selected"
            class="dropdown w-full divide-y divide-slate-100"
            @click:item="close"
          />
        </div>
      </FadeInTransition>
    </template>
  </FimMenu>
</template>

<script setup lang="ts">
import type { SortValue } from '@scayle/storefront-nuxt'

type Props = {
  values: SortValue[]
  selected: SortValue
}

const props = defineProps<Props>()

const $i18n = useI18n()

const maxValue = computed(() =>
  props.values?.reduce((res, value) => {
    const label = $i18n.t(`sorting_select.${value?.name}`)
    return label.length > res.length ? label : res
  }, ''),
)
const isDesc = computed(() => props.selected?.name === 'price_desc')
const isAsc = computed(() => props.selected?.name === 'price_asc')
</script>

<style scoped>
.btn-container::before {
  content: attr(data-max);
  font-size: 1rem;
  font-family: inherit;
  display: block;
  padding-left: 36px;
  padding-right: 16px;
  height: 0;
  visibility: hidden;
}
</style>
