import {
  type SortingValueKey,
  type SortValue,
  type Category,
  getSortByValue,
  getSortingValues,
} from '@scayle/storefront-nuxt'

import { APISortOrder } from '@aboutyou/backbone'

export const DEFAULT_SORTING_KEY: SortingValueKey = 'dateNewest'
const ALLOWED_SORTING_VALUES: SortingValueKey[] = [
  'dateNewest',
  'priceAsc',
  'priceDesc',
]

export default (selectedCategory: Ref<Category | undefined>) => {
  const route = useRoute()

  const getCustomSort = (
    category: Category | undefined,
  ): SortValue | undefined => {
    const customSorting = category?.countryLevelCustomData?.sorting as string

    if (customSorting) {
      return {
        name: 'sortingKey' as const,
        sortingKey: customSorting,
        direction: APISortOrder.Ascending,
      } as unknown as SortValue
    }
  }

  const customDefaultSorting = computed(() => {
    return getCustomSort(selectedCategory.value)
  })

  const selectedSort = computed(() => {
    if (
      (route.query.sort &&
        route.query.sort !== customDefaultSorting.value?.query) ||
      !customDefaultSorting.value
    ) {
      return getSortByValue(
        route.query.sort || '',
        DEFAULT_SORTING_KEY,
      ) as SortValue
    }
    return customDefaultSorting.value
  })

  const sortingValues = computed(() =>
    [
      customDefaultSorting.value,
      ...Object.values(getSortingValues(ALLOWED_SORTING_VALUES)),
    ].filter(Boolean),
  )

  return {
    customDefaultSorting,
    selectedSort,
    sortingValues,
  }
}
